import * as React from "react";
import Layout from "../components/layout";
// import * as sections from "../components/sections"
// import Fallback from "../components/fallback"
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../LinkTranslated";
import SimpleLocalize from "../SimpleLocalize";
import Preloader from "../components/preloader";

export default function NotFound(props) {

  return (
    <SimpleLocalize {...props}>
      <Layout>
      </Layout>
    </SimpleLocalize>
  );
}
